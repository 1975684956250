<template>
  <a-button type="primary" block :disabled="!canClick" @click="sendSms">{{ content }}</a-button>
</template>

<script>
export default {
  name: 'CountDown',
  components: {},
  props: {},
  data() {
    return {
      content: '发送验证码',
      totalTime: 60,
      canClick: true
    }
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {
    sendSms() {
      this.$emit('send')
    },
    countDown() {
      if (!this.canClick) return
      this.canClick = false
      this.content = this.totalTime + 's'
      let clock = setInterval(() => {
        this.totalTime--
        this.content = this.totalTime + 's'
        if (this.totalTime < 0) {
          clearInterval(clock)
          this.content = '发送验证码'
          this.totalTime = 60
          this.canClick = true
        }
      }, 1000)
    }
  }
}
</script>
