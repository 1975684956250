<template>
  <div class="container">
    <div class="left">
      <div class="header">
        <h2 class="animation a1" v-if="domainSettings">{{ domainSettings.organizationName || '' }}-教师空间登录</h2>
        <h4 class="animation a2">请输入账号及密码登入</h4>
      </div>
      <div class="form">
        <input
          type="email"
          class="form-field animation a3"
          placeholder="请输入账号"
          v-model="teacherData.username"
        />
        <input
          type="password"
          class="form-field animation a4"
          placeholder="请输入密码"
          v-model="teacherData.password"
        />
        <div class="d-flex align-center">
          <input
            class="form-field animation a3"
            placeholder="请输入验证码"
            v-model="teacherData.imageCode"
            @keyup.enter="onLogin"
          />
          <img
            :style="{
              width: '80px',
              height: '46px',
              marginTop: '20px',
              objectFit: 'fill'
            }"
            class="verify-image"
            :src="verifyImageUrl"
            alt="验证码"
            @click="onVerifyImageClick"
          />
      </div>
        <button class="animation a6" @click="onLogin">
          登录
        </button>
      </div>
      <br /><br /><br /><br /><br /><br />
      <div
              class="copyright"
              v-html="domainSettings.studentLoginCopyright"
              v-if="domainSettings && domainSettings.studentLoginCopyright"
      ></div>
    </div>
    <div class="right"></div>
  </div>
</template>
<script>
import md5 from 'md5'
import _ from 'lodash'
import api from '@/api'
import { mapState } from 'vuex'
import CountDown from '@/components/CountDown'
import { isPhone, isPassword, isSmsCode } from '@/utils/validate'
import { message } from 'ant-design-vue'
import helper from '@/utils/helper'
//TODO: 测试用
let teacherData = {}
if (process.env.NODE_ENV === 'development') {
  teacherData = {
    username: 'cgjy',
    password: '123456'
  }
}
export default {
  name: 'login_teacher',
  data() {
    return {
      teacherData: teacherData,
      verifyImageUrl:''
    }
  },
  computed: {
      ...mapState(['domainSettings'])
  },
  mounted() {
    const { redirect } = this.$route.query
    this.redirect = redirect || '/teacher'
    this.initVerifyImage()
    this.initOrganizationByDomain()
  },
  methods: {
    async initOrganizationByDomain() {
      const domain = window.location.origin || 100000
      let res = await api.protal.getOrganizationByDomain({ domain })
      if (res.code === 200 && res.data) {
        this.organizationId = res.data.organizationId
        this.protalTitle = res.data.organizationName || ''

        // 字符串存到数据库后再取出， 空格的编码从 '%20' -> '%C2%A0', 必须经过转换后才能正常渲染
        let str = res.data.studentLoginCopyright
        str = encodeURI(str).replaceAll('%C2%A0', '%20')
        str = decodeURI(str)
        res.data.studentLoginCopyright = str

        localStorage.setItem('organizationId', res.data.organizationId)
        this.$store.commit('setDomainSettings', res.data)
      } else {
        // TODO: 测试用
        if (
          process.env.NODE_ENV === 'development' ||
          (process.env.NODE_ENV === 'production' && process.env.VUE_APP_API_BASE_URL)
        ) {
          this.organizationId = 100000
          localStorage.setItem('organizationId', 100000)
        }
      }
      // TODO: 动态设置图标
      var link = document.querySelector("link[rel*='icon']") || document.createElement('link')
      link.type = 'image/x-icon'
      link.rel = 'shortcut icon'
      link.href = `./${this.organizationId}.ico`
      document.getElementsByTagName('head')[0].appendChild(link)
    },
    async onLogin() {
      // TODO: 老师登录
      const data = {
        ...this.teacherData,
        organizationId: this.organizationId
      }
      try {
        let res = await api.teacher.login(data)
        if (res.code === 200) {
          this.teacherData = {}
          this.teacherId = res.data.teacherId
          
          await this.$store.dispatch('loginSuccess', {
            user: res.data || {},
            role: 'TEACHER'
          })
          this.$router.replace(this.redirect)
          
        }
      } catch (err) {
        this.initVerifyImage()
      }
    },
    onVerifyImageClick: _.throttle(function() {
      this.initVerifyImage()
    }, 3000),
    async initVerifyImage() {
    // TODO: 环境配置
    let res = await api.student.verifyImage()
    if (res.code === 200) {
      this.verifyImageUrl =
        (process.env.NODE_ENV === 'development'
          ? '/api'
          : process.env.VUE_APP_API_BASE_URL
          ? process.env.VUE_APP_API_BASE_URL
          : '/') + res.data.imageURL
      this.teacherData.imageKey = res.data.imageKey
    }
   },
  },
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}

body {
  font-family: 'Rubik', sans-serif;
  margin: 0;
  padding: 0;
}

.container {
  display: flex;
  height: 100vh;
}

.left {
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  -webkit-animation-name: left;
  animation-name: left;
  -webkit-animation-duration: 0s;
  animation-duration: 0s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.right {
  flex: 1;
  background-color: black;
  transition: 0s;
  background-image: url(../../../assets/img/login/photo.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.header > h2 {
  margin: 0;
  color: #4f46a5;
}

.header > h4 {
  margin-top: 10px;
  font-weight: normal;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.4);
}

.form {
  max-width: 80%;
  display: flex;
  flex-direction: column;
}

.form > p {
  text-align: right;
}

.form > p > a {
  color: #000;
  font-size: 14px;
}

.form-field {
  height: 46px;
  padding: 0 16px;
  border: 2px solid #ddd;
  border-radius: 4px;
  font-family: 'Rubik', sans-serif;
  outline: 0;
  transition: 0s;
  margin-top: 20px;
}

.form-field:focus {
  border-color: #0f7ef1;
}

.form > button {
  padding: 12px 10px;
  border: 0;
  background: linear-gradient(to right, #de48b5 0%, #0097ff 100%);
  border-radius: 3px;
  margin-top: 10px;
  color: #fff;
  letter-spacing: 1px;
  font-family: 'Rubik', sans-serif;
  cursor: pointer;
}

.animation {
  -webkit-animation-name: move;
  animation-name: move;
  -webkit-animation-duration: 0s;
  animation-duration: 0s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.a1 {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.a2 {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.a3 {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.a4 {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.a5 {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.a6 {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

@-webkit-keyframes move {
  0% {
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px);
  }
  100% {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes move {
  0% {
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px);
  }
  100% {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@-webkit-keyframes left {
  0% {
    opacity: 0;
    width: 0;
  }
  100% {
    opacity: 1;
    padding: 20px 40px;
    width: 440px;
  }
}
@keyframes left {
  0% {
    opacity: 0;
    width: 0;
  }
  100% {
    opacity: 1;
    padding: 20px 40px;
    width: 440px;
  }
}
</style>
